import React, {lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';
import Header from './features/Header/Header';
import Footer from './features/Footer/Footer';
import HomePage from './features/HomePage/HomePage';
import ContactsPage from './features/ContactsPage/ContactsPage';
import EntranceDoorsPage from 'features/Catalog/EntranceDoorsPage';
import InteriorDoorsPage from 'features/Catalog/InteriorDoorsPage';
import PartitionPage from 'features/Catalog/PartitionPage';
import NotFound from './features/Errors/NotFound';
import { ViewportProvider } from './app/viewportContext';
import Spinner from './shared/Spinner';
import AskModal from 'features/Modals/AskModal';
import ModalPopup from 'shared/ModalPopup';
import { ErrorBoundary } from 'features/Errors/ErrorBoundary';
import SearchResultPage from 'features/Search/SearchResultPage';
import GdprModal from 'features/Modals/GdprModal';

const CatalogListPage = lazy(() => import('./features/Catalog/CatalogListPage/CatalogListPage'));
const CatalogItemPage = lazy(() => import('./features/Catalog/CatalogItem/CatalogItemPage'));

function App() {
  const [paddingTop, setPaddingtop] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  useEffect(function () {
    if (modalShow) {
      document.documentElement.classList.add('no-scroll');      
    }
  },[modalShow])

  const openModal = (title) => {
		setModalShow(true);
		setModalTitle(title);
	};

  return (
    <BrowserRouter>
      <ViewportProvider>
        <div className="App" style={{paddingTop: paddingTop}}>
          <YMInitializer accounts={[95818674]} options={{
            defer: true,
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
          }} />
          <Header setPaddingtop={setPaddingtop} openModal={openModal} />
          <ErrorBoundary>
            <Suspense fallback={<Spinner/>}>
              <Routes>
                <Route path="/" element={<HomePage openModal={openModal}/>} />
                <Route path="/vhodnye-dveri" element={<EntranceDoorsPage />} />
                <Route path="/mezhkomnatnye-dveri" element={<InteriorDoorsPage />} />
                <Route path="/razdvizhnye-peregorodki" element={<PartitionPage />} />
                <Route path="/kontakty" element={<ContactsPage openModal={openModal}/>} />
                <Route path="/search/:keyword" element={<SearchResultPage />} />
                <Route path="/:slug1/:slug2/:slug3/:slug4" element={<CatalogListPage />} />
                <Route path="/:slug1/:slug2/:slug3/:slug4/catalog/:slug" element={<CatalogItemPage />} />
                <Route path="/:slug1/:slug2/:slug3" element={<CatalogListPage />} />
                <Route path="/:slug1/:slug2/:slug3/catalog/:slug" element={<CatalogItemPage />} />
                <Route path="/:slug1/:slug2" element={<CatalogListPage />} />
                <Route path="/:slug1/:slug2/catalog/:slug" element={<CatalogItemPage />} />
                <Route path="/:slug1" element={<CatalogListPage />} />
                <Route path="/:slug1/catalog/:slug" element={<CatalogItemPage />} />
                {/* <Route path="/product/:slug" element={<CatalogItemPage />} /> */}
                <Route path='*' element={<NotFound />}/>
              </Routes>
            </Suspense>
          </ErrorBoundary>
          <Footer openModal={openModal}/>
          <GdprModal />
          {modalShow && <ModalPopup setShow={setModalShow}><AskModal setShow={setModalShow} title={modalTitle}/></ModalPopup>}
        </div>
      </ViewportProvider>
    </BrowserRouter>
  );
}

export default App;